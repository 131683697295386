const StagesConfig = [
    {
        id: 'definition',
        primaryColor: '#46CDE2',
        secondaryColor: '#D4F2F8'
    },
    {
        id: 'design',
        primaryColor: '#E7434F',
        secondaryColor: '#EDC5C7'
    },
    {
        id: 'development',
        primaryColor: '#5B1E35',
        secondaryColor: '#CFC8CB'
    },
    {
        id: 'marketing',
        primaryColor: '#272727',
        secondaryColor: '#DFDEDE'
    }
];

export default StagesConfig;
