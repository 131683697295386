import React from 'react';
import {Button, Typography, Row, Col} from 'antd';
import styles from './Hero.module.less';
import {Link} from 'gatsby';
import {useIntl} from 'gatsby-plugin-intl';
import useGlobalState from '../../hooks/useGlobalState';
import Lottie from 'react-lottie-segments';
import heroAnimationSrc from '../../assets/animations/home/hero.json';

const Hero = () => {
    const {formatMessage, locale} = useIntl();
    const {Title} = Typography;
    const globalState = useGlobalState();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: heroAnimationSrc,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const sequence = {segments: [[0, 91], [33, 91]], forceFlag: true};

    const resetActiveTab = () => globalState.setActiveCompetenciesTab('app-software-development');

    return (
        <section className={styles.section}>
            <div className="container">
                <Row>
                    <Col xs={{span: 12, order: 2}} sm={{span: 12, order: 2}} md={{span: 6, order: 1}}>
                        <Title level={1} className={styles.title}>
                            {formatMessage({id: 'home.hero.title'})}
                        </Title>
                        <Row gutter={[{xs: 30, sm: 15, lg: 30}, 30]}>
                            <Col xs={{span: 12, order: 2}} sm={{span: 5, order: 1}}>
                                <Link to={`/${locale}/services`} onClick={resetActiveTab}>
                                    <Button type="ghost" className={styles.button}>{formatMessage({id: 'button.more-info'})}</Button>
                                </Link>
                            </Col>
                            <Col xs={{span: 12, order: 1}} sm={{span: 5, order: 2}}>
                                <Link to={`/${locale}/contact`}>
                                    <Button type="primary" className={styles.button}>
                                        {formatMessage({id: 'home.hero.primary-button.label'})}
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={{span: 12, order: 1}} sm={{span: 12, order: 1}} md={{span: 6, order: 2}}>
                        <Lottie
                            options={defaultOptions}
                            playSegments={sequence}
                        />
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Hero;
