import React from 'react';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from 'gatsby';
import Lottie from 'react-lottie-segments';
import animationSrc from '../../assets/animations/home/product-stories.json'
import styles from './ProductStoriesTeaser.module.less';
import {Button, Col, Row, Tooltip, Typography} from 'antd';

const ProductStoriesTeaser = () => {
    const {formatMessage, locale} = useIntl();
    const {Title, Text} = Typography;
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationSrc,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid meet',
        },
    }

    return (
        <div className={styles.section}>
            <div className="container">
                <Title level={2} className={styles.title} align="center">
                    {formatMessage({id: 'product-stories.title'})}
                </Title>
                <Text type="paragraph" className={styles.subheading}>{formatMessage({id: 'product-stories.text'})}</Text>
                <Lottie options={defaultOptions} />
                <Row gutter={[{xs: 0, sm: 0, md: 30, lg: 30}, 30]} justify="center" className={styles.buttonsRow}>
                    <Col xs={{span: 12, order: 2}} sm={{span: 12, order: 2}} md={{span: 6, order: 1}} className="text-right text-center-mobile">
                        {/*<Link to={`/${locale}/product`}>*/}
                        <Tooltip title={formatMessage({id: 'tooltip.coming-soon'})}>
                            <Button type="ghost" disabled>
                                {formatMessage({id: 'button.go-to-products'})}
                            </Button>
                        </Tooltip>
                        {/*</Link>*/}
                    </Col>
                    <Col xs={{span: 12, order: 1}} sm={{span: 12, order: 1}} md={{span: 6, order: 2}} className="text-left text-center-mobile">
                        <Link to={`/${locale}/contact`}>
                            <Button type="primary">
                                {formatMessage({id: 'button.go-to-contact'})}
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ProductStoriesTeaser;
