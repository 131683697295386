import React from 'react';
import useGlobalState from '../../hooks/useGlobalState';
import styles from '../competencies/Competencies.module.less';
import {Avatar, Col, Row, Card, Button, Typography, Grid} from 'antd';
import {Link} from 'gatsby';
import {useIntl, FormattedMessage} from 'gatsby-plugin-intl';
import competenciesConfig from '../../configs/competencies-config'

const { useBreakpoint } = Grid;

const Competencies = () => {
    const {formatMessage, locale} = useIntl();
    const {Title, Text} = Typography;
    const globalState = useGlobalState();
    const mobile = useBreakpoint().xs;

    return (
        <div className={styles.section}>
            <div className="container">
                <Title level={3} className={styles.title}>{formatMessage({id: 'competencies.title'})}</Title>
                <Text type="paragraph" className={styles.subheading}>
                    {formatMessage({id: 'competencies.text'}, {strong: (...chunks) => <strong>{chunks}</strong>})}
                </Text>
                <Row gutter={[{xs: 0, sm: 30}, 30]}>
                    {competenciesConfig.map((item, index) => {
                        return (
                            <Col
                                xl={{span: 6}} lg={{span: 6}} md={{span: 12}} xs={{span: 12}}
                                key={index}
                                className={index % 2 === 0 ? styles.alignRight : styles.alignLeft}
                            >
                                <Link to={`/${locale}/services`} onClick={() => globalState.setActiveCompetenciesTab(item.id)}>
                                    <Card className={styles.card} bordered={false}>
                                        <Row gutter={[{xs: 0, sm: 20}, {xs: 20, sm: 0}]} align="middle" className={mobile ? 'flex-wrap' : 'flex-nowrap'}>
                                            <Col flex={mobile ? 'auto' : '80px'}>
                                                <Avatar
                                                    size={80}
                                                    icon={item.icon}
                                                    className={styles.avatar}
                                                />
                                            </Col>
                                            <Col flex='auto'>
                                                <Text className={styles.subtitle}>
                                                    {formatMessage({id: item.title})}
                                                </Text>
                                                <Text className={styles.excerpt}>
                                                    <FormattedMessage
                                                        id={item.excerpt}
                                                        values={{br: <br />}}
                                                    />
                                                </Text>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Link>
                            </Col>
                        )
                    })}
                </Row>
                <Link to={`/${locale}/services`} className={styles.buttonContainer}>
                    <Button type={'ghost'} onClick={() => globalState.setActiveCompetenciesTab('app-software-development')}>
                        {formatMessage({id: 'competencies.button'})}
                    </Button>
                </Link>
            </div>
        </div>
    );
};

export default Competencies;
