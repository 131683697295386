import React from 'react';
import styles from './StagingBlock.module.less';
import {Carousel, Col, Row, Typography} from 'antd';
import StagesConfig from '../../configs/stages-config';
import {useIntl} from 'gatsby-plugin-intl';
import {Link} from 'gatsby';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import CheckIcon from '../icons/CheckIcon';

const StagingBlock = () => {
    const {Title, Text} = Typography;
    const {formatMessage, locale} = useIntl();

    return (
        <div className={styles.section}>
            <div className="container">
                <Carousel
                    autoplay
                    autoplaySpeed={10000}
                    dotPosition="top"
                    effect="fade"
                    customPaging={(i) => (
                        <button
                            className={`ant-tabs-tab ${styles.tabButton} tab-button tab-button-${StagesConfig[i].id}`}
                        >
                            {formatMessage({id: `stage.tab-${StagesConfig[i].id}.label`})}
                        </button>
                    )}
                    appendDots={(dots) => (
                        <div>
                            <div className="ant-tabs ant-tabs-top horizontal-tabs-stepper">
                                <div className="ant-tabs-nav">
                                    <div className="ant-tabs-nav-list">
                                        {dots}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    className="custom-carousel"
                >
                    {StagesConfig.map((stage) => (
                        <div className="ant-tabs-pane" role="tabpanel">
                            <Row>
                                <Col
                                    xs={12}
                                    lg={5}
                                    className={styles.tabPaneTitle}
                                    style={{backgroundColor: stage.primaryColor}}
                                >
                                    <Title level={2}>{formatMessage({id: `stage.tab-${stage.id}.title`})}</Title>
                                    <Text>{formatMessage({id: `stage.tab-${stage.id}.text`})}</Text>
                                </Col>
                                <Col
                                    xs={12}
                                    lg={7}
                                    className={styles.tabPaneText}
                                    style={{backgroundColor: stage.secondaryColor}}
                                >
                                    <ul className={styles.list}>
                                        {[1, 2, 3, 4, 5].map((item) => (
                                            <li key={item} className={styles.listItem}>
                                                <span className={styles.listIcon}><CheckIcon /></span>
                                                <span>{formatMessage({id: `stage.tab-${stage.id}.list-item-${item}`})}</span>
                                            </li>
                                        ))}
                                    </ul>
                                    <div className="text-right">
                                        <Link to={`/${locale}/contact`} className={styles.link}>
                                            {formatMessage({id: `stage.tab-${stage.id}.link`})}
                                            <ArrowDownIcon />
                                        </Link>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default StagingBlock;
