import React from 'react';
import Competencies from '../components/competencies/Competencies';
import ShortContact from '../components/short-contact/ShortContact';
import VisibilitySensor from '../components/VisibilitySensor';
import Hero from '../components/hero/Hero'
import ProjectsTeaser from './projects-teaser/ProjectsTeaser';
import PropTypes from 'prop-types';
import StagingBlock from './staging-block/StagingBlock';
import ProductStoriesTeaser from './product-stories-teaser/ProductStoriesTeaser';

const Home = ({ edges }) => {
    const posts = edges.map((edge) => {
        return edge.node;
    });

    return (
        <div>
            <Hero />
            <VisibilitySensor>
                <StagingBlock />
            </VisibilitySensor>
            <VisibilitySensor>
                <ProductStoriesTeaser />
            </VisibilitySensor>
            <VisibilitySensor>
                <Competencies />
            </VisibilitySensor>
            <VisibilitySensor>
                <ProjectsTeaser postsList={posts} />
            </VisibilitySensor>
            <VisibilitySensor>
                <ShortContact />
            </VisibilitySensor>
        </div>
    );
};

Home.propTypes = {
    edges: PropTypes.array.isRequired,
};

export default Home;
