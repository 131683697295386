import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

const SEO = () => {
    const {formatMessage} = useIntl();

    return (
        <StaticQuery
            query={
                graphql`
                  {
                    site {
                      siteMetadata {
                        title
                        description
                        keywords
                        robots
                        author
                        googleSiteVerificationKeys
                      }
                    }
                  }`
            }
            render={({site: {siteMetadata: seo}}) => (
                    <Helmet>
                        <title>{formatMessage({ id: seo.title })}</title>
                        <meta name="description" content={formatMessage({ id: seo.description })}/>
                        <meta name="keywords" content={seo.keywords}/>
                        <meta name="robots" content={seo.robots}/>
                        <meta name="author" content={seo.author}/>
                        {seo.googleSiteVerificationKeys.map(
                            (value) => (<meta key={value} name="google-site-verification" content={value}/>)
                        )}
                    </Helmet>
                )
            }
        />
    );
};

export default SEO;
