import React from 'react'
import Layout from '../components/layout';
import 'core-js';
import 'raf/polyfill';
import Home from '../components/Home';
import SEO from '../components/Seo';
import {graphql} from 'gatsby';

const IndexPage = ({data}) => (
    <>
        <SEO />
        <Layout data={data}>
            <Home edges={data.allContentfulArticle.edges} />
        </Layout>
    </>
);

export default IndexPage;

export const pageQuery = graphql`
  query($locale: String) {
    allContentfulArticle(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          heroImage { 
            fixed(width: 600, toFormat: PNG) {
              src
            }
          }
          category { color }
        }
      }
    }
    allContentfulStory(
      sort: {fields: [category___createdAt, createdAt], order: [ASC, DESC]}, 
      filter: {node_locale: {eq: $locale}, slug: {ne: "skip"}}
    ) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulVacancy(filter: { node_locale: { eq: $locale }, slug: { ne: "skip" } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`;
